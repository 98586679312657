import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@bedrockio/pages';
import { bem } from '@bedrockio/pages/utils';
import { omit } from 'lodash';

import './button.less';

@bem
export default class Button extends React.Component {
  getModifiers() {
    const { primary, outline, round, tight } = this.props;
    return [
      primary ? 'primary' : null,
      outline ? 'outline' : null,
      round ? 'round' : null,
      tight ? 'tight' : null,
    ];
  }

  getStyles() {
    const { color } = this.props;
    if (color) {
      return {
        '--color': color,
      };
    }
  }

  render() {
    const props = omit(this.props, Object.keys(Button.propTypes));
    const { as: Component, children } = this.props;
    return (
      <Component
        {...props}
        style={this.getStyles()}
        className={this.getBlockClass()}>
        {this.renderIcon()}
        {children}
      </Component>
    );
  }

  renderIcon() {
    const { icon } = this.props;
    if (icon) {
      return <Icon size="1em" name={icon} />;
    }
  }
}

Button.propTypes = {
  as: PropTypes.elementType,
  primary: PropTypes.bool,
  outline: PropTypes.bool,
  round: PropTypes.bool,
  color: PropTypes.string,
  tight: PropTypes.bool,
  icon: PropTypes.node,
};

Button.defaultProps = {
  as: 'button',
};
