import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Meta, Spacer } from '@bedrockio/pages';

import { hasToken, setToken, request } from '@bedrockio/pages/utils';

import Header from 'components/Header';
import Button from 'components/Button';
import Container from 'components/Container';

export default function Login() {
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);

  useEffect(() => {
    if (hasToken()) {
      navigate('/');
    }
  }, []);

  function onChange(evt) {
    const { name, value } = evt.target;
    if (name === 'email') {
      setEmail(value);
    } else if (name === 'password') {
      setPassword(value);
    }
  }

  async function onSubmit(evt) {
    evt.preventDefault();
    try {
      const { data } = await request({
        method: 'POST',
        path: '/1/auth/password/login',
        body: {
          email,
          password,
        },
      });

      setToken(data.token);

      await request({
        method: 'GET',
        path: '/1/site/verify-login',
      });
      document.location = '/';
    } catch (error) {
      setToken(null);
      setError(error);
    }
  }

  return (
    <React.Fragment>
      <Meta>
        <title>Login</title>
      </Meta>
      <Header />
      <Container narrow>
        <Spacer size="lg" />
        {error && (
          <div
            style={{
              padding: '1em',
              lineHeight: '1.2',
              background: '#ee8989',
              borderRadius: '6px',
              marginBottom: '1em',
            }}>
            {error.message}
          </div>
        )}
        <form onSubmit={onSubmit}>
          <fieldset>
            <input
              type="email"
              name="email"
              value={email}
              placeholder="Email"
              autoComplete="email"
              onChange={onChange}
            />
          </fieldset>
          <Spacer size="sm" />
          <fieldset>
            <input
              type="password"
              name="password"
              value={password}
              placeholder="Password"
              autoComplete="current-password"
              onChange={onChange}
            />
          </fieldset>
          <Spacer size="sm" />
          <fieldset className="right">
            <Button primary onClick={onSubmit}>
              Login
            </Button>
          </fieldset>
        </form>
        <Spacer size="lg" />
      </Container>
    </React.Fragment>
  );
}
