import { Meta, Spacer, ImageField } from '@bedrockio/pages';
import { useClass } from '@bedrockio/pages/utils';

import Header from 'components/Header';
import Footer from 'components/Footer';
import Container from 'components/Container';

import './support.less';

export default function Support() {
  const { getElementClass } = useClass('support');

  return (
    <div>
      <Meta>
        <title>Support</title>
      </Meta>
      <Header />
      <Spacer size="lg" />
      <Container>
        <h1 className="lg">FAQ</h1>
        <Spacer />
        <div className={getElementClass('grid')}>
          <div className={getElementClass('grid-item')}>
            <div className={getElementClass('grid-item-image')}>
              <ImageField name="faq-image-1" />
            </div>
            <div className={getElementClass('grid-item-body')}>
              <h3>What are surveys?</h3>
              <p>
                Surveys are research tools used to gain information and insights
                on various topics of interest. Every user will have a different
                survey experience. Most of your surveys will be based on your
                your age, hobbies, or where you live.
              </p>
            </div>
          </div>
          <div className={getElementClass('grid-item')}>
            <div className={getElementClass('grid-item-image')}>
              <ImageField name="faq-image-2" />
            </div>
            <div className={getElementClass('grid-item-body')}>
              <h3>How long is a survey?</h3>
              <p>
                Surveys come in a variety of lengths, ranging from 5 to 45min.
                Longer surveys reward participants with more credits. Keep in
                mind, survey length is estimated, and will vary based on the
                actual survey, and your survey taking speed. They may be a bit
                longer or shorter then expected.
              </p>
            </div>
          </div>
          <div className={getElementClass('grid-item')}>
            <div className={getElementClass('grid-item-image')}>
              <ImageField name="faq-image-3" />
            </div>
            <div className={getElementClass('grid-item-body')}>
              <h3>Is every survey a good fit for me?</h3>
              <p>
                Not every survey will be a good fit for you. And most of the
                time you will only find out a couple minutes into the survey.
                But don't give up! There are better suited surveys waiting for
                you. Sometimes the algorithm will automatically redirect you to
                a survey that's a better fit for you, other times you'll need to
                start over and select a new survey.
              </p>
            </div>
          </div>
          <div className={getElementClass('grid-item')}>
            <div className={getElementClass('grid-item-image')}>
              <ImageField name="faq-image-4" />
            </div>
            <div className={getElementClass('grid-item-body')}>
              <h3>What to expect from a survey?</h3>
              <p>
                It is normal for surveys to have some repetitive questions. If
                the survey is not moving to the next question, scroll to the
                bottom to see if there is a "next" button. Loading and
                refreshing during surveys happens. Please allow surveys to load
                fully (especially at the end!), to ensure you are properly
                rewarded.
              </p>
            </div>
          </div>
          <div className={getElementClass('grid-item')}>
            <div className={getElementClass('grid-item-image')}>
              <ImageField name="faq-image-5" />
            </div>
            <div className={getElementClass('grid-item-body')}>
              <h3>What should I do when I get stuck?</h3>
              <p>
                In this imperfect world, it's possible for our app to experience
                glitches now and then. Should you encounter any, please reach
                out to our customer service team or explore our FAQs for
                support.
              </p>
            </div>
          </div>
        </div>
      </Container>
      <Spacer size="xl" />
      <Footer />
    </div>
  );
}
