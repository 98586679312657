import React, { useState } from 'react';
import { set } from 'lodash';
import { Meta, Spacer, Form, ExternalLink } from '@bedrockio/pages';

import {
  useClass,
  useMergedState,
  request,
  onMount,
} from '@bedrockio/pages/utils';

import Header from 'components/Header';
import Button from 'components/Button';
import Container from 'components/Container';
import PhoneField from 'components/form-fields/Phone';
import EmailField from 'components/form-fields/Email';

import { loadScript } from 'utils/script';
import { TURNSTILE_SITE_KEY } from 'utils/env';

import { US_STATES } from './const';

import './checkout.less';

export default function Checkout() {
  const { className, getElementClass } = useClass('checkout');

  const [error, setError] = useState(null);
  const [complete, setComplete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState('');
  const [fields, setFields] = useMergedState();

  onMount(() => {
    renderTurnstile();
  });

  async function renderTurnstile() {
    if (!TURNSTILE_SITE_KEY) {
      setToken('token');
      return;
    }
    /* global turnstile */
    await loadScript(
      'https://challenges.cloudflare.com/turnstile/v0/api.js?render=explicit',
    );
    turnstile.render('#cf-turnstile', {
      theme: 'light',
      sitekey: TURNSTILE_SITE_KEY,
      callback: async function (token) {
        setToken(token);
      },
    });
  }

  function setField({ name, value }) {
    const newFields = { ...fields };
    set(newFields, name, value);
    setFields(newFields);
  }

  async function onSubmit() {
    try {
      setError(null);
      setLoading(true);
      await request({
        method: 'POST',
        path: '/1/signup',
        body: {
          token,
          ...fields,
          ...getReferer(),
        },
      });
      localStorage.removeItem('referer');
      setLoading(false);
      setComplete(true);
    } catch (error) {
      setError(error);
      setLoading(false);
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }, 100);
    }
    setLoading(false);
  }

  function getReferer() {
    const referer = localStorage.getItem('referer');
    if (referer) {
      return {
        referer,
      };
    }
  }

  function renderComplete() {
    return (
      <React.Fragment>
        <h3>Submission Complete!</h3>
        <Spacer size="sm" />
        <p>Thanks for signing up for Free.Mobile!</p>
        <Spacer size="xs" />
        <p>
          We will send your new SIM card in <b>5 business days</b>.
        </p>
      </React.Fragment>
    );
  }

  function renderForm() {
    return (
      <React.Fragment>
        <Spacer size="md" />
        <h1 className="lg">Checkout</h1>
        <Spacer size="md" />
        {renderError()}
        <p>
          Please provide your shipping information and we will send your
          Free.Mobile SIM card in <b>5 business days</b>. Please make sure to
          provide a valid email address as its needed to confirm your shipment.
        </p>
        <Spacer size="sm" />
        <Form
          className={getElementClass('form')}
          onSubmit={onSubmit}
          loading={loading}>
          <Form.Input
            required
            name="firstName"
            label="First Name"
            value={fields.firstName}
            onChange={setField}
            error={error}
          />
          <Form.Input
            required
            name="lastName"
            label="Last Name"
            value={fields.lastName}
            onChange={setField}
            error={error}
          />
          <Form.Input
            required
            name="address.street"
            label="Street Address"
            value={fields.address?.street}
            onChange={setField}
            error={error}
          />
          <Form.Input
            name="address.unit"
            label="Apartment, suite, unit, etc"
            value={fields.address?.unit}
            onChange={setField}
            error={error}
          />
          <Form.Input
            required
            name="address.city"
            label="Town / City"
            value={fields.address?.city}
            onChange={setField}
            error={error}
          />
          <Form.Select
            required
            name="address.state"
            label="State"
            value={fields.address?.state}
            options={US_STATES}
            onChange={setField}
            error={error}
          />
          <Form.Input
            required
            name="address.zipcode"
            label="Zip"
            value={fields.address?.zipcode}
            onChange={setField}
            error={error}
          />
          <EmailField
            required
            name="email"
            label="Email"
            value={fields.email}
            onChange={setField}
            error={error}
          />
          <PhoneField
            name="phone"
            label="Phone"
            value={fields.phone}
            onChange={setField}
            error={error}
          />
          <Spacer size="sm" />
          <Button>Submit</Button>
          <div id="cf-turnstile" />
          <p>
            By clicking submit I agree to Free Mobile's{' '}
            <ExternalLink to="/terms">Terms and Conditions</ExternalLink> and{' '}
            <ExternalLink to="/privacy">Privacy Policy</ExternalLink>
          </p>
        </Form>
      </React.Fragment>
    );
  }

  function renderError() {
    if (error) {
      return <div className="error-message">{error.message}</div>;
    }
  }

  return (
    <div className={className}>
      <Meta>
        <title>Checkout</title>
      </Meta>
      <Header />
      <Spacer size="sm" />
      <Container narrow>{complete ? renderComplete() : renderForm()}</Container>
      <Spacer size="xl" />
    </div>
  );
}
