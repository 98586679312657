import React from 'react';
import PropTypes from 'prop-types';
import { Form } from '@bedrockio/pages';

export default class EmailField extends React.Component {
  render() {
    return (
      <Form.Input
        name="email"
        type="email"
        autoComplete="email"
        {...this.props}
      />
    );
  }
}

EmailField.propTypes = {
  error: PropTypes.instanceOf(Error),
};
