import React from 'react';
import PropTypes from 'prop-types';
import { omit } from 'lodash';

import { Form, Input } from '@bedrockio/pages';

import { formatPhone } from 'utils/phone';

export default class PhoneField extends React.Component {
  onChange = ({ value, ...rest }) => {
    value = value.replace(/[ ()@.+-]/g, '');
    value = value.replace(/^[01](\d)/, '$1');
    value = value.replace(/[a-z]/gi, '');
    value = value.trim();
    if (value) {
      if (!value.startsWith('+1')) {
        value = `+1${value}`;
      }
    } else {
      value = undefined;
    }
    this.props.onChange({
      value,
      ...rest,
    });
  };

  render() {
    const { value } = this.props;
    return (
      <Form.Field {...this.props}>
        <Input
          {...omit(this.props, Object.keys(PhoneField.propTypes))}
          type="tel"
          autoComplete="tel"
          onChange={this.onChange}
          value={formatPhone(value, 'us')}
          ref={this.ref}
        />
      </Form.Field>
    );
  }
}

PhoneField.propTypes = {
  label: PropTypes.string,
  error: PropTypes.instanceOf(Error),
};

PhoneField.defaultProps = {
  value: '',
};
