import w300 from './home2@300w.avif';
import w600 from './home2@600w.avif';
import w1200 from './home2@1200w.avif';

export default {
  ratio: 1.301,
  images: [
    {
      size: 300,
      url: w300,
    },
    {
      size: 600,
      url: w600,
    },
    {
      size: 1200,
      url: w1200,
    },
  ],
};