import React from 'react';

import { Field, Spacer } from '@bedrockio/pages';
import { useData } from '@bedrockio/pages/stores';

import Header from 'components/Header';
import Container from 'components/Container';

import './custom.less';

export default function CustomPage(props) {
  const { getPageField } = useData();

  const { type, path } = props;
  if (type === 'article') {
    return (
      <React.Fragment>
        <Field name={getPageField(path, 'image')} type="image" />
        <h1>
          <Field name={getPageField(path, 'title')} />
        </h1>
        <article>
          <Field name={getPageField(path, 'body')} type="text" />
        </article>
      </React.Fragment>
    );
  } else if (type === 'legal') {
    return (
      <React.Fragment>
        <Header />
        <Spacer size="lg" />
        <article className="legal-page">
          <Container>
            <Field name={getPageField(path, 'body')} type="text" />
          </Container>
        </article>
        <Spacer />
      </React.Fragment>
    );
  }
}
