import React from 'react';
import { Link } from 'react-router-dom';
import { bem } from '@bedrockio/pages/utils';

import './jump-link.less';

@bem
export default class JumpLink extends React.Component {
  onClick = () => {
    setTimeout(this.scrollToElement, 100);
  };

  scrollToElement = () => {
    const { to } = this.props;
    const id = to.match(/#(.*)/)?.[1];
    if (id) {
      const el = document.getElementById(id);
      el.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      });
      this.props.onClick?.();
    }
  };

  render() {
    return (
      <Link
        {...this.props}
        onClick={this.onClick}
        className={this.getBlockClass()}
      />
    );
  }
}
