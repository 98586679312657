import { useEffect } from 'react';
import { Icon, ErrorBoundary } from '@bedrockio/pages';
import { useLocation } from 'react-router-dom';

import ErrorPage from 'pages/Error';
import icons from 'assets/icons.svg';

import Router from './Router';

import './app.less';

Icon.useSet(icons);

const ALTERNATES = ['/how-it-works', '/checkout'];

// TODO: temp while we work out crisp
if (typeof window !== 'undefined') {
  const params = new URLSearchParams(location.search);
  if (params.get('crisp')) {
    const script = document.createElement('script');
    script.async = true;
    script.innerHTML = `window.$crisp=[];window.CRISP_WEBSITE_ID="3eff5b2e-a525-4054-bf6b-e6bb1a2dda2c";(function(){d=document;s=d.createElement("script");s.src="https://client.crisp.chat/l.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();`;
    document.body.appendChild(script);
  }
}

export default function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    const isAlternate = ALTERNATES.includes(pathname);
    document.documentElement.classList.toggle('alternate', isAlternate);
  }, [pathname]);

  const className = ALTERNATES.includes(pathname) ? 'alternate' : '';
  return (
    <main className={className}>
      <ErrorBoundary fallback={<ErrorPage />}>
        <Router />
      </ErrorBoundary>
    </main>
  );
}
