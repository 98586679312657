import { Link } from 'react-router-dom';
import { Icon } from '@bedrockio/pages';
import { useClass } from '@bedrockio/pages/utils';

import Drawer from 'components/Drawer';
import Container from 'components/Container';
import JumpLink from 'components/JumpLink';
import logo from 'assets/images/logo.svg';

import './header.less';

export default function Header() {
  const { className, getElementClass } = useClass('header');

  return (
    <Container>
      <header className={className}>
        <Link to="/" className={getElementClass('logo')}>
          <img src={logo} width="100" />
        </Link>
        <nav className={getElementClass('desktop-nav')}>
          <JumpLink to="/#about">About</JumpLink>
          <JumpLink to="/#how-it-works">How It Works</JumpLink>
          <Link to="/coverage">Coverage</Link>
          <Link to="/support">Support</Link>
        </nav>
        <Drawer trigger={<Icon name="menu" />}>
          <div className={getElementClass('nav-container')}>
            <nav className={getElementClass('mobile-nav')}>
              <Link to="/">Home</Link>
              <JumpLink to="/#about">About</JumpLink>
              <JumpLink to="/#how-it-works">How It Works</JumpLink>
              <Link to="/coverage">Coverage</Link>
              <Link to="/support">Support</Link>
            </nav>
          </div>
        </Drawer>
      </header>
    </Container>
  );
}
